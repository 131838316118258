import React from "react"
import { BasicLayout } from "../components/layout"
import { Link as GatsbyLink } from "gatsby"
import Seo from "../components/seo"
import { HiArrowLeft } from "react-icons/hi"

const NotFountPage = () => {
  return (
    <BasicLayout>
      <Seo title="404" />
      <main className="flex flex-col h-screen justify-center items-center space-y-6">
        <p className="text-8xl font-bold">404</p>
        <div>
          <p className="whitespace-pre-line italic text-center">{
            ` Wind catches lily.
              Scatt'ring petals to the wind:
              Your site is not found. `
          }</p>
        </div>
        <GatsbyLink to="/">
          <button className="text-xl flex hover:opacity-75 font-bold">
            <HiArrowLeft className="my-auto h-auto" />
            <p>返回首页</p>
          </button>
        </GatsbyLink>
      </main>
    </BasicLayout>
  )

}

export default NotFountPage